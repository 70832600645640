<template>
  <div>
    <el-carousel :height="autoHeight">
      <el-carousel-item v-for="(item, i) in swiperList" :key="i">
        <img @click="toDetail(item.id)" :src="item.img" alt />
      </el-carousel-item>
    </el-carousel>
    <div class="historyBox hidden-xs-only">
      <!-- 品牌起源 -->
      <div class="title flex a_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/6458adcfdaf71.png"
          alt=""
        />
        <div>品牌起源</div>
      </div>
      <div class="historyText">
        Vinistyle，中文名称为薇妮，是江苏苏美国际时尚管理有限公司旗下品牌，创立于2008年。品牌初期通过整合韩国化妆品供应链，借助韩国化妆品生产的先进技术，推出了大量时尚、优质的护肤和彩妆产品，并通下线下化妆品连锁经营模式，迅速获得了广大消费者的信任和喜爱。短短几年时间，专卖店开遍全国20多个省份，众多通过开设薇妮专卖店创业的加盟商也从中受益。<br />
        优秀的产品品质是薇妮在竞争激烈的化妆品行业中得以立足的根本，薇妮化妆品通过与韩国“蜗牛霜之父”表博士联合定制开发的薇妮蜗牛霜畅销10年仍然热卖，所推出的“一小时彩护妆”服务、清洁三宝清洁服务，是较早实践化妆品店轻护理概念的品牌之一。<br />
        随着市场环境和消费者习惯的变化，薇妮品牌与时俱进，不断升级产品和服务，与国内外一流的化妆品研发和生产机构合作，专注于专业院线产品和功效型护肤品的研发，依托多年来积累的行业资源，薇妮在轻护理的基础上，专研美容护理服务套组方案，将门店服务升级为皮肤管理项目，以满足消费者日益增加的爱美需求。<br />
      </div>
      <!-- 品牌理念 -->
      <div class="title flex a_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/6458adcfdaf71.png"
          alt=""
        />
        <div>品牌理念</div>
      </div>
      <div class="flex a_c">
        <div class="historyText">
          Vinistyle意指薇妮的风格，寓意所有女孩都有独一无二、属于自己的风格，而每个女孩大胆追寻美丽与梦想的过程，正是发现自我和绽放独特魅力的过程。<br />
          随着社会不断进步，女性对健康和美的追求也愈加强烈，薇妮认为女性的美源自女性自我价值的实现，我们希望每一位女性通过使用薇妮产品或服务，改善肌肤问题，保持年轻状态，看到自己独特的一面，从而更加独立自信。
        </div>
        <img
          class="ideaImg"
          src="https://static.5t1800.com/s3/2023/05/08/6458af7c0fb45.png"
          alt=""
        />
      </div>
      <!-- 研发与供应链 -->
      <div class="title flex a_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/6458adcfdaf71.png"
          alt=""
        />
        <div>研发与供应链</div>
      </div>
      <div class="historyText">
        薇妮产品研发本着科学护肤之道，严格杜绝伪科学、智商税产品。本着开放协作的精神，薇妮与国内外优秀研发机构展开广泛合作，力求将护肤领域最新科技、专利成分、功效配方应于与产品之中。无论皮肤管理项目，还是居家护肤产品，每个SKU均经过效果测试，进行正规备案后才推向市场，让经营者安心，消费者放心。<br />
        对于不同品类的产品，我们会严选适合的供应链合作，比如韩国著名化妆品企业韩佛化妆品，面膜类专业工厂诺斯贝尔，据有20多年专业线产品生产历史的暨源生物等等。
      </div>
      <img
        class="produceImg"
        src="https://static.5t1800.com/s3/2023/05/08/6458b043838a5.png"
        alt=""
      />
      <!-- 薇妮门店 -->
      <div class="title flex a_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/6458adcfdaf71.png"
          alt=""
        />
        <div>薇妮门店</div>
      </div>
      <div class="historyText">
        从2008年第一家专卖店开始，Vinistyle门店就以独特而充满个性的风格吸引着消费者的眼光，正如品牌理念所倡导的，薇妮门店形象一直在不断升级变化，每一次升级都是一次华丽的蜕变，每座城市的薇妮Vinistyle专卖店都是独一无二的，或可爱唯美、或专业简约、或个性时尚，薇妮因所处商圈环境不同而展现出独特的气质。
      </div>
      <div class="storeImgs flex a_c j_c">
        <img v-for="(item, i) in storeList" :key="i" :src="item" alt="" />
      </div>
      <!-- 未完待续 -->
      <div class="flex a_c j_c">
        <div class="end">
          未完待续...... <br />
          欢迎加入薇妮、一起书写新的故事
        </div>
      </div>
    </div>

    <div class="historyBoxPhone hidden-sm-and-up">
      <!-- 品牌起源 -->
      <div class="title flex a_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/6458adcfdaf71.png"
          alt=""
        />
        <div>品牌起源</div>
      </div>
      <div class="historyText">
        Vinistyle，中文名称为薇妮，是江苏苏美国际时尚管理有限公司旗下品牌，创立于2008年。品牌初期通过整合韩国化妆品供应链，借助韩国化妆品生产的先进技术，推出了大量时尚、优质的护肤和彩妆产品，并通下线下化妆品连锁经营模式，迅速获得了广大消费者的信任和喜爱。短短几年时间，专卖店开遍全国20多个省份，众多通过开设薇妮专卖店创业的加盟商也从中受益。<br />
        优秀的产品品质是薇妮在竞争激烈的化妆品行业中得以立足的根本，薇妮化妆品通过与韩国“蜗牛霜之父”表博士联合定制开发的薇妮蜗牛霜畅销10年仍然热卖，所推出的“一小时彩护妆”服务、清洁三宝清洁服务，是较早实践化妆品店轻护理概念的品牌之一。<br />
        随着市场环境和消费者习惯的变化，薇妮品牌与时俱进，不断升级产品和服务，与国内外一流的化妆品研发和生产机构合作，专注于专业院线产品和功效型护肤品的研发，依托多年来积累的行业资源，薇妮在轻护理的基础上，专研美容护理服务套组方案，将门店服务升级为皮肤管理项目，以满足消费者日益增加的爱美需求。<br />
      </div>
      <!-- 品牌理念 -->
      <div class="title flex a_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/6458adcfdaf71.png"
          alt=""
        />
        <div>品牌理念</div>
      </div>
      <div class="">
        <div class="historyText">
          Vinistyle意指薇妮的风格，寓意所有女孩都有独一无二、属于自己的风格，而每个女孩大胆追寻美丽与梦想的过程，正是发现自我和绽放独特魅力的过程。<br />
          随着社会不断进步，女性对健康和美的追求也愈加强烈，薇妮认为女性的美源自女性自我价值的实现，我们希望每一位女性通过使用薇妮产品或服务，改善肌肤问题，保持年轻状态，看到自己独特的一面，从而更加独立自信。
        </div>
        <img
          class="ideaImg"
          src="https://static.5t1800.com/s3/2023/05/08/6458af7c0fb45.png"
          alt=""
        />
      </div>
      <!-- 研发与供应链 -->
      <div class="title flex a_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/6458adcfdaf71.png"
          alt=""
        />
        <div>研发与供应链</div>
      </div>
      <div class="historyText">
        薇妮产品研发本着科学护肤之道，严格杜绝伪科学、智商税产品。本着开放协作的精神，薇妮与国内外优秀研发机构展开广泛合作，力求将护肤领域最新科技、专利成分、功效配方应于与产品之中。无论皮肤管理项目，还是居家护肤产品，每个SKU均经过效果测试，进行正规备案后才推向市场，让经营者安心，消费者放心。<br />
        对于不同品类的产品，我们会严选适合的供应链合作，比如韩国著名化妆品企业韩佛化妆品，面膜类专业工厂诺斯贝尔，据有20多年专业线产品生产历史的暨源生物等等。
      </div>
      <img
        class="produceImg"
        src="https://static.5t1800.com/s3/2023/05/08/6458b043838a5.png"
        alt=""
      />
      <!-- 薇妮门店 -->
      <div class="title flex a_c">
        <img
          src="https://static.5t1800.com/s3/2023/05/08/6458adcfdaf71.png"
          alt=""
        />
        <div>薇妮门店</div>
      </div>
      <div class="historyText">
        从2008年第一家专卖店开始，Vinistyle门店就以独特而充满个性的风格吸引着消费者的眼光，正如品牌理念所倡导的，薇妮门店形象一直在不断升级变化，每一次升级都是一次华丽的蜕变，每座城市的薇妮Vinistyle专卖店都是独一无二的，或可爱唯美、或专业简约、或个性时尚，薇妮因所处商圈环境不同而展现出独特的气质。
      </div>
      <div class="storeImgs flex a_c j_c">
        <img v-for="(item, i) in storeList" :key="i" :src="item" alt="" />
      </div>
      <!-- 未完待续 -->
      <div class="flex a_c j_c">
        <div class="end">
          未完待续...... <br />
          欢迎加入薇妮、一起书写新的故事
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperList: [
        {
          img: "https://static.5t1800.com/s3/2023/05/08/6458aba4ac0d7.jpg",
          id: 306,
        },
      ],
      storeList: [
        "https://static.5t1800.com/s3/2023/05/08/6458b20b0a08c.png",
        "https://static.5t1800.com/s3/2023/05/08/6458b20eecdf9.png",
        "https://static.5t1800.com/s3/2023/05/08/6458b211715a1.png",
        "https://static.5t1800.com/s3/2023/05/08/6458b213ade40.png",
        "https://static.5t1800.com/s3/2023/05/08/6458b204297bc.png",
        "https://static.5t1800.com/s3/2023/05/08/6458b2083ce3d.png",
        "https://static.5t1800.com/s3/2023/05/08/6458b2172556b.png",
        "https://static.5t1800.com/s3/2023/05/08/6458b21b92af5.png",
      ],
      autoHeight: (window.innerWidth * 512) / 1920 - 5 + "px",
    };
  },
  metaInfo: {
    title: "Vinistyle 薇妮", // set a title
    meta: [
      {
        name: "keyWords",
        content: "薇妮vinistyle,薇妮,薇妮美肤,薇尼美肤空间,护肤品,化妆品加盟,薇妮化妆品加盟,化妆品,薇妮化妆品,美白,防晒,防晒霜",
      },
      {
        name: "description",
        content: "vinistyle薇妮是江苏苏美国际旗下化妆品品牌，主营功效性护肤产品和解决方案，包括美容院专业线套组以及居家护肤产品，针对用户常见的斑、痘、敏、皱四大类肌肤问题，都有针对性的产品和解决方案。vinistyle薇妮提供整店输出、产品合作、项目合作等各种合作方式，诚邀美容院、皮肤管理工作、化妆品实体店加盟合作，共创美丽事业！",
      },
    ],
  },
  components: {},
  created() {
    this.$store.commit("saveTypeIndex", 1);
  },
  mounted() {
    window.onresize = () => {
      this.autoHeight = (window.innerWidth * 512) / 1920 - 5 + "px";
    };
  },
  methods: {},
};
</script>

<style scoped>
img {
  width: 100%;
}
.historyBox {
  max-width: 1200px;
  margin: 0 auto;
}
.historyBox .title {
  padding: 24px 0;
}
.historyBox .title img {
  width: 64px;
  height: 64px;
}
.historyBox .title div {
  width: 363px;
  height: 58px;
  background: linear-gradient(90deg, #ffffff 0%, #fbcdf1 100%);
  font-size: 35px;
  font-weight: 800;
  color: #7e7d7d;
  text-align: center;
  line-height: 58px;
}
.historyBox .historyText {
  font-size: 25px;
  font-weight: 300;
  color: #7e7d7d;
  line-height: 42px;
}
.historyBox .ideaImg {
  width: 100%;
}
.historyBox .produceImg {
  width: 100%;
}
.historyBox .storeImgs {
  flex-wrap: wrap;
  padding: 10px 0;
}
.historyBox .storeImgs img {
  width: 570px;
  height: 350px;
  margin-bottom: 30px;
  margin-right: 30px;
}
.historyBox .end {
  font-size: 25px;
  font-weight: 300;
  color: #7e7d7d;
  display: inline-block;
  margin: 0 auto;
  padding: 50px 0;
}





.historyBoxPhone {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding:8px
}
.historyBoxPhone .title {
  padding: 20px 0;
}
.historyBoxPhone .title img {
  width: 48px;
  height: 48px;
}
.historyBoxPhone .title div {
  width: 263px;
  height: 48px;
  background: linear-gradient(90deg, #ffffff 0%, #fbcdf1 100%);
  font-size: 24px;
  font-weight: 800;
  color: #7e7d7d;
  text-align: center;
  line-height: 48px;
}
.historyBoxPhone .historyText {
  font-size: 20px;
  font-weight: 300;
  color: #7e7d7d;
  line-height: 32px;
}
.historyBoxPhone .ideaImg {
  width: 100%;
}
.historyBoxPhone .produceImg {
  width: 100%;
}
.historyBoxPhone .storeImgs {
  flex-wrap: wrap;
  padding: 10px 0;
}
.historyBoxPhone .storeImgs img {
  width: 100%;
  margin-bottom: 30px;
}
.historyBoxPhone .end {
  font-size: 22px;
  font-weight: 300;
  color: #7e7d7d;
  display: inline-block;
  margin: 0 auto;
  padding: 30px 0;
}
</style>

